
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Filter } from '@/types/ListTypes';
import Multiselect
  from '@/components/DataTable/DataTableFilter/MultiselectFilter.vue';

@Component({
  name: 'TagsFilter',
  components: {
    Multiselect,
  },
})
export default class TagsFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;
}
